import React from 'react'
import Layout from "../../../../components/layout";
import HeaderPaginas from '../../../../components/headerPaginas'
import Seo from '../../../../components/SEO/SEO';
import Boton from '../../../../components/boton';
import CtaServicios from '../../../../components/ctaServicios';
import { Link } from 'gatsby';


export default function SecDominios(props) {

    return (
        <Layout>
            <Seo pathname={props.location.pathname} />
            <div className="cabecera-servicio">
                <HeaderPaginas titulo="Securización de dominios" imagen="/img/cabeceras/securizacion-dominio-bullhost.jpg" />
                <div className="intro-servicio">
                    <p>Estamos expuestos cada vez más a casos de fraude, suplantaciones, robo o secuestro y/o exposición de nuestros datos, y la suplantación en nuestro email es una de las acciones más frecuentes de los ciberdelincuentes.</p>
                </div>
            </div>
            <div className="cuerpo-servicio">
                <section className="limitador">
                    <p className="parrafo-destacado">Si nuestro dominio es suplantable ellos son capaces de escribir desde nuestra cuenta de correo sin mayor problema.
                        Grave, ¿verdad? </p>
                </section>
                <section className="limitador bloque-grid">
                    <div className="titulo destacado">
                        <h2>¿Cómo lo puedo solucionar? </h2>
                    </div>
                    <div className="texto">
                        <p>Securiza tu dominio. En Bullhost securizamos tu dominio para que suplantarte NO SEA UNA OPCIÓN PARA ELLOS.</p>
                        <p>Un dominio se securiza modificando y protegiendo determinados parámetros en el mismo que impiden que alguien se haga pasar por ti y con tu email.
                            No todos los proveedores de hosting lo ejecutan, de hecho, el 90% no lo hace, es un trabajo delicado y personalizado y la rentabilidad de los grandes hoster no pasa por ahí.
                            Por Bullhost si.</p>
                        <p>Ofrecemos a nuestros clientes que tienen alojados sus dominios en nuestro <Link to="/cloud-robusto/">CPD</Link> su securización y, si contratas un servicio de <Link to="/servicios/cloud/hosting-web/">hosting securizado</Link> está incluído en el mismo.</p>
                    </div>
                </section>
            </div>
            <section className="bloques-partidos">
                <section className="bloque-acento">
                    <div className="limitador contenido__servicio">
                        <h2 className="titulo_contenido">¿Quieres saber si es seguro tu dominio?</h2>
                        <p>Nuestro equipo ha desarrollado una app para que puedas saber si tu dominio es seguro y si, por tanto, estás en riesgo con tu email.</p>
                        <p><strong>¡En menos de 1 minuto tendrás el resultado! ¿Te atreves?</strong></p>
                        <p>Esta aplicación, creada en Python por <a href="https://bullhost.agency" target="_blank" rel="noreferrer noopener">bullhost.agency</a>, permite verificar en un momento si un correo corporativo es suplantable.</p>
                        <Boton color="secundario" url="https://esmidominioseguro.bullhost.security" texto="Accede a la app" externo={true} />
                    </div>
                </section>
                <section className="bloque-acento" style={{ backgroundImage: "url(/img/bullhito_escalado.jpg)" }}>
                    <div className="link-app">
                    </div>
                </section>
            </section>
            <CtaServicios />
        </Layout>
    )
}